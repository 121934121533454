import ParticlesBG from "./particlesBG";

const Background = () => {
  return (
    <div>
      <ParticlesBG />
    </div>
  );
};

export default Background;
