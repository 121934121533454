// Frontend Numen
import thumbnail from "../assets/img/projects/front-numen/numen-front-thumbnail.png";
import img1 from "../assets/img/projects/front-numen/numen-front.png";

const frontNumen = {
  thumbnail,
  assets: [img1],
};

export default frontNumen;
