import Home from "./home";
import Projects from "./projects";
import About from "./about";
import Contact from "./contact";

const MainComps = {
  Home,
  Projects,
  About,
  Contact,
};

export default MainComps;
