// Python Calculator
import thumbnail from "../assets/img/projects/calculator-py/calculator-py-thumbnail.png";
import video from "../assets/video/projects/calculator-py/calculator-py.mp4";
import img1 from "../assets/img/projects/calculator-py/calculator-py-example.png";

const calculatorPy = {
  thumbnail,
  assets: [video, img1],
};

export default calculatorPy;
